import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row }  from 'react-bootstrap';

import { useAuth } from "utils/auth.js";


const Signin = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function login(event) {
    event.preventDefault();
    auth.signin(
      email,
      password,
      () => {
        navigate('/', {replace: true});
      }
    );
  }

  return (
    <Fragment>
      <Container fluid='md'>
        <Row>
          <Col className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <Card>
              <Card.Body>
                <Container>
                  <Row>
                    <Col>
                      <h3 className="text-center">Sign In</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-8 offset-md-2">
                      <Form onSubmit={login}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className="mb-3">
                          <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="float-end">
                          Sign In
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Signin;
