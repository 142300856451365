import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';

import { ProvideAuth } from './utils/auth.js';
import history from './utils/history.js';
import Signin from 'screens/Signin';
import PrivateRoute from 'components/PrivateRoute'
import EventsIncoming from 'screens/EventsIncoming'
import Sources from 'screens/Sources'
import AddSource from 'screens/AddSource'
import Application from 'containers/Application'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
  <>
  {/* add on react-bootstrap upgrade to 2
  <React.StrictMode>
  */}
    <ProvideAuth>
      <BrowserRouter history={history}>
        <Routes>
          <Route path='/signin/' element={<Signin />} />
          <Route path='/' element={<Navigate to='/app/' />} />
          <Route path='/app//*' element={<PrivateRoute><Application /></PrivateRoute>}>
            <Route path='' element={<Navigate from='/app/' to='/app/events/incoming/' />} />
            <Route path='events/incoming/' element={<EventsIncoming />} />
            <Route path='sources/add/' element={<AddSource />} />
            <Route path='sources/' element={<Sources />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ProvideAuth>
  {/*
  </React.StrictMode>,
  */}
  </>
);
