import React from 'react';
import { Fragment } from 'react';

import api from 'utils/api';
import { Spinner, Button, Col, Row }  from 'react-bootstrap';
import { toast } from 'react-toastify';

const Sources = () => {

  const [sources, setSources] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchSources = React.useCallback(() => {
    api
      .get('/source/')
      .then(res => {
        const { data } = res;
        if (data) {
          let sorted_data = [...data].sort((a, b) => a.sourceruns[0]?.display_started_at > b.sourceruns[0]?.display_started_at ? 1 : -1)
          setSources(sorted_data);
        }
        setLoading(false);
      })
  }, []);

  React.useEffect(() => {
    fetchSources();
  }, [fetchSources]);

  const onSourceRun = React.useCallback((pk) => {
    setLoading(true);

    api
      .post('/source/' + pk.toString() + '/run/')
      .then(res => {
        toast.info(
          <div>
            <span>Success!</span>
          </div>
        )
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        fetchSources();
      });

  }, [fetchSources]);

  return (
    <>
    {loading ? <Spinner /> :
    <>
      <Row>
        <Col className='text-center'>
          <h5 className='my-0'>ИСТОЧНИКИ ({sources.length})</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className='mt-1 mb-0 pb-0' />
        </Col>
      </Row>
      {sources.map(event => (
        <Fragment key={event.pk}>
        <Row className='pt-3 pb-1'>
          <Col>{event.title}<br />{event.slug}</Col>
          <Col><div>{event.sourceruns[0]?.display_started_at}</div><div>{event.sourceruns[0]?.display_completed_at}</div></Col>
          <Col>
            <div>Добавлено: {event.sourceruns[0]?.display_events_modified}</div>
            <div>Новые: {event.sourceruns[0]?.display_events_added}</div>
            <div>Измененные: {event.sourceruns[0]?.display_events_changed}</div>
            <div>Ичсезли: {event.sourceruns[0]?.display_events_disappeared}</div>
            <div>Нет изменений: {event.sourceruns[0]?.display_events_not_modified}</div>
          </Col>
          <Col>
            <Button onClick={() => onSourceRun(event.pk)}>Run</Button>
          </Col>
        </Row>
        </Fragment>
      ))}
    </>
    }
    </>

  )
}

export default Sources;
