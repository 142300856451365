import React, { useContext, useState, createContext } from "react";
import { toast } from 'react-toastify';

import api from 'utils/api';

const authContext = createContext();
const USER_KEY = 'user';

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(() => {
    let storage_user = localStorage.getItem(USER_KEY);
    if(storage_user)
      return JSON.parse(storage_user);
  });

  const signin = (email, password , callback) => {
    api.post(
      '/signin/',
      {
        email: email,
        password: password
      },
    ).then(res => {
      setUser(res.data);
      localStorage.setItem(USER_KEY, JSON.stringify(res.data));
      callback();      
    }).catch(err => {
      toast.info('Invalid credentials');
    })
  }

  const signout = () => {
    api.post(
      '/signout/'
    ).then(res => {
      setUser(null);
      localStorage.removeItem(USER_KEY);
    }).catch(err => {
    })
  };

  const cleanup = () => {
    localStorage.removeItem(USER_KEY);
  }

  const renew = () => {
    api.get(
      '/user/' + user.id + '/',
    ).then(res => {
      setUser(res.data);
      localStorage.setItem(USER_KEY, JSON.stringify(res.data));
    })
  };

  return {
    user,
    signin,
    signout,
    cleanup,
    renew,
  };
}
