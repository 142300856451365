import React from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'utils/api';
import { Form, Table, Button, Col, Row }  from 'react-bootstrap';

const AddSource = () => {

  const navigate = useNavigate();

  const [title, setTitle] = React.useState(null);
  const [instagramSlug, setInstagramSlug] = React.useState(null);
  const [defaultLocationText, setDefaultLocationText] = React.useState('');

  const addSource = React.useCallback(() => {
    api
      .post('/source/', {
        'title': title,
        'slug': instagramSlug,
        'url': 'https://www.instagram.com/' + instagramSlug + '/',
        'default_location_text': defaultLocationText,
        'type': 'instagram_channel',
      })
      .then(res => {
        navigate('/app/sources/')
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      })
  }, [title, navigate, instagramSlug, defaultLocationText]);

  return (
    <>
      <Row className='pt-3 pb-1'>
        <Col>
          <Table bordered striped>          
            <tbody>
              <tr>
                <td className='fw-bold'>Instagram слаг</td>
                <td>
                  <Form.Control
                    type='text'
                    onChange={e => setInstagramSlug(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Заголовок</td>
                <td>
                  <Form.Control
                    type='text'
                    onChange={e => setTitle(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className='fw-bold'>Локация по умолчанию</td>
                <td>
                  <Form.Control
                    type='text'
                    onChange={e => setDefaultLocationText(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>          
          <Button onClick={() => addSource()}>Добавить</Button>
        </Col>
      </Row>
    </>
  )
}

export default AddSource;
