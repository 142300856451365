import React from 'react';
import { Plus, ArrowClockwise } from 'react-bootstrap-icons';
import { Fragment } from 'react';
import { Carousel, Modal, Spinner, Button, Col, Row, Table, Image, Tabs, Tab, Form }  from 'react-bootstrap';
import { toast } from 'react-toastify';


import { REACT_APP_DJANGO_ADMIN_URL, REACT_APP_WEBSITE_URL } from 'utils/settings'

import api from 'utils/api';


const EventsIncoming = () => {
  const [loading, setLoading] = React.useState(false);

  const [events, setEvents] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [eventsCount, setEventsCount] = React.useState(0);
  const [allEventsSwitch, setAllEventsSwitch] = React.useState(true);

  const [renewUpdates, setRenewUpdates] = React.useState([]);
  const [publishUpdates, setPublishUpdates] = React.useState([]);

  const [confirmDialogShow, setConfirmDialogShow] = React.useState(false);
  const [renewPK, setRenewPK] = React.useState(null);

  const fetchEvents = React.useCallback(() => {
    var url = '/event/incoming/';
    if(!allEventsSwitch)
        url += '?page=1';

    api
      .get(url)
      .then(res => {
        const { data } = res;
        if (data) {
          console.log(data);
          if(allEventsSwitch) {
            setEvents(data);
            setEventsCount(data.length);
          } else {
            setEvents(data.results);
            setEventsCount(data.count);
          }
          setRenewUpdates([]);
          setPublishUpdates([]);
        }
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [setEvents, setLoading, setEventsCount, setRenewUpdates, setPublishUpdates, allEventsSwitch]);

  const fetchEventTypes = React.useCallback(() => {
    var url = '/event/types/';

    api
      .get(url)
      .then(res => {
        const { data } = res;
        if (data) {
          console.log(data);
          setEventTypes(data);
        }
      })
  }, [setEventTypes]);

  const refresh = React.useCallback(() => {
    setLoading(true);
    fetchEvents();
  }, [fetchEvents, setLoading]);

  React.useEffect(() => {
    fetchEventTypes();
    refresh();
  }, [refresh, fetchEventTypes]);

  const hideEvent = React.useCallback((pk) => {
    const newEvents = [...events];
    events.forEach((event, index) => {
      if(event['pk'] === pk) {
        newEvents.splice(index, 1);
      }   
    })
    setEvents(newEvents);
    setEventsCount(eventsCount-1);
  }, [events, eventsCount, setEvents, setEventsCount]);

  const checkUrlSlug = React.useCallback((url_slug) => {
    api
      .post('/event/checkurlslug/', {'url_slug': url_slug})
      .then(res => {
        const { data } = res;
        console.log(data);
        if(data.url_slug_exists)
          toast.info(
            <div>
              <span>Url slug {url_slug} exists!</span>
            </div>
          )
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
      });
  }, []);

  const onEventNotEvent = React.useCallback((pk) => {
    setLoading(true);

    api
      .post('/event/' + pk.toString() + '/notevent/')
      .then(res => {
        toast.info(
          <a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + pk.toString() + '/change/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Event discarded as not event. </span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [setLoading, hideEvent]);

  const onEventNotPublish = React.useCallback((pk) => {
    setLoading(true);

    api
      .post('/event/' + pk.toString() + '/notpublish/')
      .then(res => {
        toast.info(
          <a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + pk.toString() + '/change/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Event discarded as not to publish. </span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [setLoading, hideEvent]);

  const onEventPublish = React.useCallback((pk) => {
    setLoading(true);

    let params = {};

    if(pk in publishUpdates)
      params = publishUpdates[pk]

    if('dates' in params)
      for(var date in params['dates']) {
        if(params['dates'][date].start_date === '')
          params['dates'][date].start_date = null;
        if(params['dates'][date].end_date === '')
          params['dates'][date].end_date = null;
      }

    api
      .post('/event/' + pk.toString() + '/publish/', params)
      .then(res => {
        const { data } = res;
        let published_event = data;
        toast.info(
          <a href={REACT_APP_WEBSITE_URL + 'event/' + published_event.url_slug + '/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Published Event</span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [publishUpdates, setLoading, hideEvent]);

  const onEventHide = React.useCallback((pk) => {
    setLoading(true);

    api
      .post('/event/' + pk.toString() + '/hide/')
      .then(res => {
        toast.info(
          <a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + pk.toString() + '/change/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Event hided </span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [setLoading, hideEvent]);

  const onEventOutdated = React.useCallback((pk) => {
    setLoading(true);

    api
      .post('/event/' + pk.toString() + '/outdated/')
      .then(res => {
        toast.info(
          <a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + pk.toString() + '/change/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Event outdated </span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [setLoading, hideEvent]);


  const handleConfirmDialogClose = React.useCallback(() => {
    setConfirmDialogShow(false);
  }, []);

  const handleConfirmDialogShow = React.useCallback(() => {
    setConfirmDialogShow(true);
  }, []);

  const eventRenew = React.useCallback((pk) => {
    handleConfirmDialogClose();
    setLoading(true);

    let params = {};

    if(pk in renewUpdates)
        params = renewUpdates[pk];

    api
      .post('/event/' + pk.toString() + '/renew/', params)
      .then(res => {
        const { data } = res;
        let renewed_event = data;

        toast.info(
          <a href={REACT_APP_WEBSITE_URL + 'event/' + renewed_event.url_slug + '/'} target='_blank' rel='noreferrer'>
            <div>
              <span>Success! </span>
              <span>Renewed Event</span>
            </div>
          </a>
        )
        hideEvent(pk);
      }).catch(err => {
        toast.error('Error, stop all work and report to admin!');
      }).finally(() => {
        setLoading(false);
      });
  }, [renewUpdates, setLoading, handleConfirmDialogClose, hideEvent]);

  const onEventRenew = React.useCallback((pk) => {
    setRenewPK(pk);

    let event = null;
    for(let e in events) {
      if(events[e].pk === pk) {
        event = events[e];
      }
    }

    let diff = [];

    if(event.diff) {
      Object.keys(event.diff).map((key, index) => diff.push(key));
    }

    let params = [];
    if(pk in renewUpdates)
      params = renewUpdates[pk];

    for(let d in diff) {
      if(!(diff[d] in params)) {
        handleConfirmDialogShow();
        return;
      }
    }
    eventRenew(pk);

  }, [events, renewUpdates, eventRenew, handleConfirmDialogShow]);

  const onPropertyRenewApply = React.useCallback((pk, prop, value) => {
    var newRenewUpdates = [...renewUpdates];

    if(!(pk in newRenewUpdates))
      newRenewUpdates[pk] = {};
    newRenewUpdates[pk][prop] = value;

    setRenewUpdates(newRenewUpdates);
  }, [renewUpdates]);

  const onDateAdd = React.useCallback((pk) => {
    var newPublishUpdates = [...publishUpdates];

    if(!(pk in newPublishUpdates)) {
      newPublishUpdates[pk] = {}
    }
    if(!('dates' in newPublishUpdates[pk])) {
      newPublishUpdates[pk]['dates'] = [];
    }
    var dates = newPublishUpdates[pk]['dates'];

    dates.push({
      'start_date': '',
      'end_date': '',
      'is_start_time_present': true,
      'is_end_time_present': false,
    })

    newPublishUpdates[pk]['dates'] = dates;
    setPublishUpdates(newPublishUpdates);
  }, [publishUpdates]);


  const onPropertyDatePublishApply = React.useCallback((pk, index, prop, value) => {
    var newPublishUpdates = [...publishUpdates];
    var dates = newPublishUpdates[pk]['dates'];

    dates[index][prop] = value;

    newPublishUpdates[pk]['dates'] = dates;
    setPublishUpdates(newPublishUpdates);
  }, [publishUpdates]);

  const onPropertyPublishApply = React.useCallback((pk, prop, value) => {
    console.log(prop);
    console.log(value);

    if(prop === 'url_slug' && value !== '') {
      checkUrlSlug(value);
    }
    var newPublishUpdates = [...publishUpdates];

    if(!(pk in newPublishUpdates))
      newPublishUpdates[pk] = {};
    newPublishUpdates[pk][prop] = value;

    setPublishUpdates(newPublishUpdates);
  }, [publishUpdates, checkUrlSlug]);

  const handleConfirmDialogProceed = React.useCallback(() => {
    setConfirmDialogShow(true);
    eventRenew(renewPK);
  }, [eventRenew, renewPK]);

  const onSlugCopyPaste = React.useCallback((slug) => {
    navigator.clipboard.writeText(slug);
    toast.info('Copied!')
  }, []);


  return (
    <>
      <Row>
        <Col>
          <Form.Check
            type='switch'
            label='All events'
            checked={allEventsSwitch}
            onChange={e => setAllEventsSwitch(e.target.checked)}
          />
        </Col>
        <Col className='text-center col-6'>
          <h5 className='my-0'>ВХОДЯЩИЕ СОБЫТИЯ ({eventsCount})</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className='pt-1 ps-2' style={{'cursor': 'pointer'}} onClick={refresh}><ArrowClockwise size={32} /></div>
        </Col>
      </Row>
      {events && events.map(event => (
        <Fragment key={event.pk}>
        <Row>
          <Col>
            <hr className='mt-1 mb-2 pb-0' />
          </Col>
        </Row>
        <Row className='pb-1'>
          <Col className='col-lg-7'>
            {event.is_disappeared &&
              <>
                <Button onClick={() => onEventHide(event.pk)} className='me-2'>Исчез, спрятать</Button>
              </>
            }
            {event.published_events_list.length === 0 &&
              <>
                <Button onClick={() => onEventNotEvent(event.pk)} className='me-2' size="sm">Не событие</Button>
                <Button onClick={() => onEventPublish(event.pk)} className='me-2' size="sm">Опубликовать</Button>
                <Button onClick={() => onEventNotPublish(event.pk)} className='me-2' size="sm">Не надо</Button>
                <Button onClick={() => onEventOutdated(event.pk)} className='me-2' size="sm">Прошло</Button>
              </>
            }
            {event.published_events_list.length !== 0 &&
              <>
                <Button onClick={() => onEventRenew(event.pk)}>Обновить</Button>
              </>
            }
          </Col>
          <Col className='col-lg-3'>
            <span>{event.source_display}</span>
            {event.source_type === 'web_site' &&
              <span className='badge text-bg-primary mx-2'>W</span>  
            }
          </Col>
          <Col className='col-lg-2'>
            <span className='badge text-bg-primary' style={{ cursor: 'pointer' }} onClick={() => onSlugCopyPaste(event.slug)}>{event.slug}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className='mt-1 mb-2 pb-0' />
          </Col>
        </Row>
        <Row className='pt-1 pb-1'>
          <Col lg="4" className='pb-3'>
            {event.images.length > 1 &&
              <Carousel
                indicators={true}
                interval={null}
                onSelect={(index) => onPropertyPublishApply(event.pk, 'image_index', index)}
              >
                {event.images.map(image => (    
                  <Carousel.Item key={image.pk}>
                    <Image src={image.url} style={{width: '100%', height: 'auto'}}/>
                  </Carousel.Item>
                ))}
              </Carousel>
             }
            {event.images.length === 1 &&
              <a href={event.images[0].url} target="_blank" rel="noreferrer">
                <Image src={event.images[0].url} style={{width: '100%', height: 'auto'}}/>
              </a>
            }
          </Col>
          <Col lg="8">
            <Tabs
              defaultActiveKey='html'
              className='mb-3'
            >
              <Tab eventKey='html' title='HTML'>
                <div dangerouslySetInnerHTML={{__html: event.description_display }} />
              </Tab>            
              <Tab eventKey='raw' title='Raw' mountOnEnter>
                {event.description}
              </Tab>            
              <Tab eventKey='edit' title='Edit'>
                <Form.Control
                  as='textarea'
                  defaultValue={event.description}
                  rows={10}
                  onChange={e => onPropertyPublishApply(event.pk, 'description', e.target.value)}
                />
              </Tab>            
            </Tabs>
          </Col>
        </Row>
        <Row className='pt-1 pb-1'>
          <Col>
            <Table bordered striped>
              <tbody>
                {event.diff &&
                <tr>
                  <td
                    style={{backgroundColor: "LightSlateGray", color: "white"}}
                    className='align-middle'
                    colSpan="2"
                  >
                    <span>Различия</span>
                  </td>
                </tr>
                } 
                {event.diff && Object.keys(event.diff).map((key, index) => (
                <Fragment key={key}>
                <tr>
                  <td className='fw-bold'>{key}</td>
                  <td>
                    <Form.Check
                      checked={renewUpdates?.[event.pk]?.[key] === true ? true : false }
                      type='switch'
                      label='Применить'
                      onChange={e => onPropertyRenewApply(event.pk, key, e.target.checked)}
                    />
                  </td>
                </tr>
                {key !== 'images' &&
                <tr>
                  <td>
                    {key === 'dates' ? 
                      <pre>{String(event.diff[key]['previous'])}</pre> : 
                      <span>{String(event.diff[key]['previous'])}</span>
                    }
                  </td>
                  <td>
                    {key === 'dates' ? 
                      <pre>{String(event.diff[key]['current'])}</pre> :
                      <span>{String(event.diff[key]['current'])}</span>
                    }
                  </td>
                </tr>
                }
                {key === 'images' &&
                <>
                <tr>
                  <td><Image src={event.diff['images']['image']['previous']} style={{width: '100%', height: 'auto'}}/></td>
                  <td><Image src={event.diff['images']['image']['current']} style={{width: '100%', height: 'auto'}}/></td>
                </tr>
                {event.diff['images']['image_md5'] &&
                <tr>
                  <td>{event.diff['images']['image_md5']['previous']}</td>
                  <td>{event.diff['images']['image_md5']['current']}</td>
                </tr>
                }
                {!event.diff['images']['image_md5'] && 
                <tr>
                  <td colSpan='2'>Одинаковый MD5</td>
                </tr>
                }
                </>
                }
                </Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='pt-1 pb-1'>
          <Col>
            <Table bordered striped>
              <tbody>
                {event.is_disappeared &&
                <tr>
                  <td className='fw-bold'>ИСЧЕЗ !!!</td>
                  <td></td>
                </tr>
                }
                <tr>
                  <td className='fw-bold'>Источник</td>
                  <td>{event.source_display}</td>
                </tr>
                <tr>
                  <td className='fw-bold'>Ссылка</td>
                  <td>
                    <a href={event.source_page_url} rel='nofollow noopener noreferrer' target='_blank'>{event.source_page_url}</a>
                  </td>
                </tr>
                {event.appeared_at_display &&
                <tr>
                  <td className='fw-bold'>Опубликовано</td>
                  <td>{event.appeared_at_display}</td>
                </tr>
                }
                <tr>
                  <td className='fw-bold'>Заголовок</td>
                  <td>
                    <Form.Control
                      type='text'
                      defaultValue={event.title}
                      onChange={e => onPropertyPublishApply(event.pk, 'title', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'>Локация</td>
                  <td>
                    <Form.Control
                      type='text'
                      defaultValue={event.location_text}
                      onChange={e => onPropertyPublishApply(event.pk, 'location_text', e.target.value)}
                    />
                  </td>
                </tr>
                {event.default_location_text &&
                <tr>
                  <td className='fw-bold'>Локация по умолчанию</td>
                  <td>{event.default_location_text}</td>
                </tr>
                }
                <tr>
                  <td className='fw-bold'>Тип события</td>
                  <td>
                    <Form.Select
                      onChange={e => onPropertyPublishApply(event.pk, 'event_type', e.target.value)}
                      defaultValue={event.event_type}
                    >
                      <option value=''>-</option>
                      {eventTypes.map(eventType => (
                        <option key={eventType.value} value={eventType.value}>
                          {eventType.label}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'>Слаг</td>
                  <td>
                    <span><a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + event.pk + '/change/'} target='_blank' rel='noreferrer'>{event.slug}</a></span>
                  </td>
                </tr>
                {event.published_events_list.length === 0 &&
                <tr>
                  <td className='fw-bold'>URL Слаг</td>
                  <td>
                    <Form.Control
                      type='text'
                      defaultValue={event.sanitized_slug}
                      onChange={e => onPropertyPublishApply(event.pk, 'url_slug', e.target.value)}
                    />
                  </td>
                </tr>
                }
                {event.published_events_list.length !== 0 &&
                <tr>                
                  <td className='fw-bold'>URL Слаг</td> 
                  <td>
                    {event.published_events_list.map(published_event => (
                      <div key={published_event.pk}>
                        <span>{published_event.url_slug} </span>                      
                      </div>
                    ))}
                  </td>
                </tr>
                }
                {event.previous_version_event &&
                <tr>
                  <td className='fw-bold'>Предыдущая версия</td>
                  <td>
                    <a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + event.previous_version_event + '/change/'} target='_blank' rel='noreferrer'>#B {event.previous_version_event}</a>
                  </td>
                </tr>
                }
                {event.published_events_list.length !== 0 &&
                <tr>
                  <td className='fw-bold'>Опубликованные события</td>
                  <td>
                    {event.published_events_list.map(published_event => (
                      <div key={published_event.pk}>
                        <span><a href={REACT_APP_DJANGO_ADMIN_URL + 'events/event/' + published_event.pk + '/change/'} target='_blank' rel='noreferrer'>#B {published_event.pk}</a> </span>
                        <span><a href={REACT_APP_WEBSITE_URL + 'event/' + published_event.url_slug + '/'} target='_blank' rel='noreferrer'>#F {published_event.pk}</a> </span>
                      </div>
                    ))}
                  </td>
                </tr>
                }
                {event.is_instagram === false &&
                <>
                <tr>
                  <td className='fw-bold'>Дата (Отображение)</td>
                  <td>{event.date_display}</td>
                </tr>
                <tr>
                  <td className='fw-bold'>Стоимость (Отображение)</td>
                  <td>{event.price_display}</td>
                </tr>
                </>
                }
                <tr>
                  <td className='fw-bold'>Стоимость От</td>
                  <td>
                    <Form.Control
                      type='text'
                      defaultValue={event.price_from}
                      onChange={e => onPropertyPublishApply(event.pk, 'price_from', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'>Стоимость До</td>
                  <td>
                    <Form.Control
                      defaultValue={event.price_to}
                      onChange={e => onPropertyPublishApply(event.pk, 'price_to', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'>Стоимость Диапозон</td>
                  <td>
                    <Form.Check
                      defaultChecked={event.price_range}
                      onChange={e => onPropertyPublishApply(event.pk, 'price_range', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold'>Стоимость Бесплатно</td>
                  <td>
                    <Form.Check
                      type='checkbox'
                      defaultChecked={event.price_free}
                      onChange={e => onPropertyPublishApply(event.pk, 'price_free', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{backgroundColor: "LightSlateGray", color: "white"}}
                    className='align-middle'
                    colSpan="2"
                  >
                    <span>Даты</span>
                    <span className="float-end" style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onDateAdd(event.pk)}>
                      <Plus size={30} as="span" />
                    </span>
                  </td>
                </tr>
                {publishUpdates?.[event.pk]?.dates?.map((date, index) => (
                <Fragment key={index}>
                <tr>
                  <td>
                    <Form.Control
                      type='datetime-local'
                      value={date.start_date}
                      onChange={e => onPropertyDatePublishApply(event.pk, index, 'start_date', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='datetime-local'
                      value={date.end_date}
                      onChange={e => onPropertyDatePublishApply(event.pk, index, 'end_date', e.target.value)}
                    />
                  </td>
                </tr>                 
                <tr>
                  <td>
                    <Form.Check
                      checked={date.is_start_time_present}
                      onChange={e => onPropertyDatePublishApply(event.pk, index, 'is_start_time_present', e.target.checked)}
                      label='Start time present'
                    />
                  </td>
                  <td>
                    <Form.Check
                      checked={date.is_end_time_present}
                      onChange={e => onPropertyDatePublishApply(event.pk, index, 'is_end_time_present', e.target.checked)}
                      label='End time present'
                    />
                  </td>
                </tr>                 
                </Fragment>
                ))}
                {event.dates.map(date => (
                <tr key={date.pk}>
                  <td>{date.start_date_display}</td>
                  <td>{date.end_date_display}</td>
                </tr>                 
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        </Fragment>
      ))}
      <Modal
        show={confirmDialogShow}
        onHide={handleConfirmDialogClose}
        keyboard={false}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Не все измененные элементы выделены. Продолжить?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmDialogClose}>Нет</Button>
          <Button variant="primary" onClick={handleConfirmDialogProceed}>Да</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={loading}
        keyboard={false}
        backdrop='static'
        centered
      >
        <Modal.Body className='text-center'>
          <Spinner animation='border' />
          <div>Loading...</div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EventsIncoming;
