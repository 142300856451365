import React from 'react';

import { useState }  from 'react';
import { Container, Navbar, Nav }  from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


const Application = () => {

  const [navExpanded, setNavExpanded] = useState(false);

  React.useEffect(() => {
    const closeToastOnEscape = (e) => {
      if (e.keyCode === 27) {
        toast.dismiss();
      }
    };
    window.addEventListener('keydown', closeToastOnEscape);

    return () => {
      window.removeEventListener('keydown', closeToastOnEscape);
    };
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand='xs' expanded={navExpanded} onToggle={(toggle) => setNavExpanded(toggle)}>
        <Container fluid>
          <Nav />
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav>
              <Link to='/app/events/incoming/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Входящие события</Link>
            </Nav>
            <Nav>
              <Link to='/app/sources/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Источники</Link>
            </Nav>
            <Nav>
              <Link to='/app/sources/add/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Добавить Источник</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid='md' className='mb-2'>
        <Outlet />
        <ToastContainer
          autoClose={false}
        />
      </Container>
    </>
  )
}

export default Application;
